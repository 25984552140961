var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.notiPermissionStatus == "default"
        ? _c(
            "v-card",
            { attrs: { color: "warning lighten-1" } },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", [
                  _c("h3", { staticClass: "headline mb-0" }, [
                    _vm._v("Enable Notifications")
                  ]),
                  _c("div", [
                    _vm._v(
                      "\n          To stay connected and receive important updates such as:\n          "
                    ),
                    _vm.isSeekerMode
                      ? _c("ul", [
                          _c("li", [_vm._v("Messages from recruiters")]),
                          _c("li", [
                            _vm._v("Job openings you would be interested in")
                          ]),
                          _c("li", [
                            _vm._v(
                              "Valuable tips to help improve your chances of being noticed"
                            )
                          ])
                        ])
                      : _vm._e(),
                    !_vm.isSeekerMode
                      ? _c("ul", [
                          _c("li", [
                            _vm._v("Responses from candidates you contacted")
                          ]),
                          _c("li", [
                            _vm._v(
                              "Candidates applying for your open positions"
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c("small", [
                      _vm._v(
                        "Warning: If you block notifications, you will not receive important updates. And you'll need to reset app settings to enable them back."
                      )
                    ])
                  ])
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-btn", { attrs: { flat: "", color: "primary" } }, [
                    _vm._v("Later")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.requestNotiPermission($event)
                        }
                      }
                    },
                    [_vm._v("Allow")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.notiPermissionStatus == "denied"
        ? _c(
            "v-card",
            { attrs: { color: "error darken-1", dark: "" } },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", [
                  _c("h3", { staticClass: "headline mb-0" }, [
                    _vm._v("Notifications Blocked!")
                  ]),
                  _c("div", [
                    _vm._v(
                      "\n          Unfortunatly, we won't be able to send you updates on\n          this device unless enable notifications manually by resetting site settings.\n          Tap below to learn how:\n          "
                    ),
                    _c("br"),
                    _c(
                      "a",
                      {
                        staticStyle: { "text-decoration": "none" },
                        attrs: {
                          target: "_blank",
                          href:
                            "https://www.google.com/search?q=How+to+enable+site+notifications+on+" +
                            _vm.browserName
                        }
                      },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              value: true,
                              color: "white",
                              outline: "",
                              icon: "open_in_new"
                            }
                          },
                          [
                            _vm._v(
                              "Google: How to enable site notifications on " +
                                _vm._s(_vm.browserName)
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      this.notificationGranted
        ? [
            _c(
              "v-list",
              { staticClass: "py-0", attrs: { "two-line": "" } },
              [
                _vm._l(_vm.notifications, function(noti, index) {
                  return [
                    _c(
                      "v-list-tile",
                      {
                        key: noti.id,
                        class: { "warning lighten-3": !noti.readInAppDate },
                        attrs: { avatar: "", ripple: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.notiClick(noti)
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-tile-avatar",
                          [
                            _c("v-icon", {
                              staticClass: "grey lighten-1 white--text",
                              domProps: {
                                textContent: _vm._s(
                                  noti.notification.iconName || "notifications"
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile-content",
                          [
                            _c("v-list-tile-title", {
                              domProps: {
                                textContent: _vm._s(noti.notification.title)
                              }
                            }),
                            _c("v-list-tile-sub-title", {
                              domProps: {
                                textContent: _vm._s(noti.notification.body)
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile-action",
                          [
                            _c("v-list-tile-action-text", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(
                                    noti.creationDate,
                                    "calendar"
                                  )
                                )
                              )
                            ]),
                            noti.readInAppDate
                              ? _c(
                                  "v-icon",
                                  { attrs: { color: "grey lighten-1" } },
                                  [_vm._v("notifications")]
                                )
                              : _c(
                                  "v-icon",
                                  { attrs: { color: "yellow darken-2" } },
                                  [_vm._v("notifications")]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    index + 1 < _vm.notifications.length
                      ? _c("v-divider", { key: "d" + index })
                      : _vm._e()
                  ]
                })
              ],
              2
            ),
            _c(
              "div",
              { class: _vm.hasMore ? "ma-3" : "mx-3" },
              [
                _vm.hasMore
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          round: "",
                          depressed: "",
                          xcolor: "primary",
                          xdark: "",
                          loading: _vm.loadingMore
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.loadMoreNotifications($event)
                          }
                        }
                      },
                      [_vm._v("More")]
                    )
                  : _vm._e(),
                !_vm.hasMore
                  ? _c("dot-steps", {
                      attrs: { "dot-color": "no-color", "current-step": 0 }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }