import * as models from "@/models";

export class dummyAPI {
    static fetchApplicants = ({ take = 5 } = {}): Promise<models.paged<models.seekerLabel[]>> => Promise.resolve({
        items: repeat(applicants, take).map((applicant, i) => ({
            ...applicant,
            content: i % 2 ? "Applied" : "Shortlisted",
            jobPost: i % 3 === 1 ? { title: "Test Job Post" } : null
        })),
        hasNext: true,
        hasPrevious: false,
        skipped: 0,
        taken: 10,
    }).then(delay(1500));//.then(() => Promise.reject('some error'));
}

const delay = <T>(ms: number) => (result: T) => new Promise<T>(resolve => setTimeout(() => resolve(result), ms));
const repeat = <T extends { id?}>(obj: T, n: number) => {
    const result = [];
    for (let i = 0; i < n; ++i) {
        result.push({ ...(obj as object), id: (+obj.id || 0) + i })
    }
    return result;
}

const applicants =
{
    "organization": null,
    "seekerProfile": {
        "id": 1856,
        "industry": {
            "parent": {
                "parent": null,
                "children": null,
                "id": 23,
                "title": "Arts and Cultural",
                "nodeLevel": 0
            },
            "children": null,
            "id": 25,
            "title": "Heritage and Museums",
            "nodeLevel": 1
        },
        "city": {
            "countryCode": "UA",
            "id": "Feodosiya, UA",
            "name": "Feodosiya",
            "geoLocation": {
                "latitude": 45.036770,
                "longitude": 35.377890
            },
            "isActive": true
        },
        "headline": "TestTitle",
        "jobTypes": [],
        "firstname": "Test",
        "lastname": "Seeker",
        "fullname": "Test Seeker",
        "currentlyOpen": true,
        "isViewable": true,
        "seekerVideos": [
            {
                "skrId": 1856,
                "vidId": 202,
                "isPublic": true,
                "isPrimary": true,
                "order": 0,
                "video": {
                    "vidId": 202,
                    "vidUri": "https://meinone1media1dev.blob.core.windows.net/videos/fcf0da04-d9f8-42ed-a985-077b3795539a--1856.mp4",
                    "vidThumbUriComputed": "https://meinone1media1dev.blob.core.windows.net/videos/fcf0da04-d9f8-42ed-a985-077b3795539a--1856.jpg",
                    "vidTitle": "",
                    "vidStatusId": 200,
                    "vidStatusTitle": "Ready"
                }
            }
        ],
        "context": {},
        "languages": []
    },
    "jobPost": null,
    "id": 57,
    "content": "Applied",
    "creationDate": "2019-06-26T16:07:00"
};

