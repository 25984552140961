



















































































































































































import Vue from "vue";
import * as models from "@/models";
import { dummyAPI } from "@/code/api.orgs.fake";
import { messaging } from "@/code/api.messaging";
import { jobs } from "@/code/api.jobs.orgs";
import { accounts } from "@/code/api.accounts";
import { G } from "@/globals";
import JobCardsList from "@/components/jobs/jobCardsList.vue";
import SeekerCardsList from "@/components/seekers/seekerCardsList.vue";
import SeekerCard from "@/components/seekers/seekerCard.vue";

const JOB_PUBLISHED = models.jobPostStatusString.published;
const JOB_NOTLISTED = models.jobPostStatusString.notListed;
const JOB_EXPIRED = models.jobPostStatusString.expired;

export default Vue.extend({
  name: "orgsHome",
  components: { JobCardsList, SeekerCardsList, SeekerCard },
  data() {
    return {
      isLoadingThreads: false,
      threads: <models.thread[]>[],
      threadsError: null,
      isLoadingApplicants: false,
      applicants: {
        all: { items: <models.seekerProfile[]>[], hasMore: false },
        saved: { items: <models.seekerProfile[]>[], hasMore: false },
        applied: { items: <models.seekerProfile[]>[], hasMore: false }
      },
      applicantsError: null,
      jobPosts: {
        [JOB_PUBLISHED]: {
          items: <models.jobPost[]>[],
          hasNext: false,
          isLoading: false,
          error: null
        },
        [JOB_NOTLISTED]: {
          items: <models.jobPost[]>[],
          hasNext: false,
          isLoading: false,
          error: null
        },
        [JOB_EXPIRED]: {
          items: <models.jobPost[]>[],
          hasNext: false,
          isLoading: false,
          error: null
        }
      },

      applicantsFilters: [
        {
          text: "All",
          filter: applicant => true,
          status: "all",
          select: applicants => applicants.all
        },
        {
          text: "Saved",
          filter: applicant => applicant.content !== "Applied",
          status: "saved",
          select: applicants => applicants.saved
        },
        {
          text: "Applied",
          filter: applicant => applicant.content === "Applied",
          status: "applied",
          select: applicants => applicants.applied
        }
      ],
      selectedApplicantFilter: null,
      jobPostFilters: [
        {
          text: "Live",
          filter: post => post.isListed,
          status: JOB_PUBLISHED,
          select: posts => posts[JOB_PUBLISHED]
        },
        {
          text: "Draft",
          filter: post => !post.isListed,
          status: JOB_NOTLISTED,
          select: posts => posts[JOB_NOTLISTED]
        },
        {
          text: "Expired",
          status: JOB_EXPIRED,
          select: posts => posts[JOB_EXPIRED]
        }
      ],
      selectedJobPostFilter: null
    };
  },
  methods: {
    async fetchThreads(take: number) {
      this.isLoadingThreads = true;
      this.threadsError = null;
      try {
        const orgId = accounts.currentOrgId;
        const pagedThreads = await messaging.getActiveThreads(0, take, orgId);
        this.threads = pagedThreads.items;
      } catch (err) {
        this.threadsError = err;
        G.log.error("Home.Orgs.fetchThreads", err);
      } finally {
        this.isLoadingThreads = false;
      }
    },
    async fetchApplicants() {
      this.isLoadingApplicants = true;
      this.applicantsError = null;
      try {
        let applicants = await dummyAPI.fetchApplicants({ take: 5 });
        this.applicantsFilters.forEach(filter => {
          this.applicants[filter.status] = {
            items: applicants.items.filter(filter.filter),
            hasNext: applicants.hasNext
          };
        });
      } catch (e) {
        this.applicantsError = e;
      } finally {
        this.isLoadingApplicants = false;
      }
    },
    async fetchJobPosts(status: models.jobPostStatusString) {
      const posts = this.jobPosts[status];
      if (posts.isLoading) {
        return;
      }
      posts.isLoading = true;
      posts.error = null;
      try {
        const currentOrg = accounts.currentOrg;
        const pagedPosts = await jobs.orgs.posts.getByOrgId_Paged(
          currentOrg.id,
          0,
          5,
          false,
          status
        );
        posts.items = pagedPosts.items;
        posts.hasNext = pagedPosts.hasNext;
      } catch (err) {
        posts.error = err;
        G.log.error("Home.Orgs.fetchJobPosts", err);
      } finally {
        posts.isLoading = false;
      }
    },
    avatarFor(seeker: models.seekerProfile) {
      if (
        seeker &&
        seeker.seekerVideos &&
        seeker.seekerVideos[0] &&
        seeker.seekerVideos[0].video &&
        seeker.seekerVideos[0].video.vidThumbUriComputed
      ) {
        return seeker.seekerVideos[0].video.vidThumbUriComputed;
      }
      return null;
    },
    openJobPost(jpx: models.jobPostExtended) {
      this.$router.push({
        name: "Orgs_JobPosts__JobPost",
        query: {
          id: jpx.id.toString()
        },
        params: <any>{
          passedJobPost: jpx
        }
      });
    }
  },
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.smAndUp;
    },
    filteredThreads() {
      if (this.isMobile && this.threads.length > 3) {
        return this.threads.slice(0, 3);
      }
      return this.threads;
    },
    filteredApplicants() {
      let filter = this.selectedApplicantFilter;
      return filter.select(this.applicants);
    },
    filteredJobPosts() {
      let filter = this.selectedJobPostFilter;
      return filter.select(this.jobPosts);
    }
  },
  watch: {
    selectedJobPostFilter(value) {
      this.fetchJobPosts(value.status);
    }
  },
  created() {
    this.selectedApplicantFilter = this.applicantsFilters[0];
    this.selectedJobPostFilter = this.jobPostFilters[0];
  },
  mounted() {
    this.fetchThreads(this.isMobile ? 3 : 10);
    this.fetchApplicants();
    this.fetchJobPosts(JOB_PUBLISHED);
  }
});
