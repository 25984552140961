var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "m-page",
    { attrs: { "parent-class": _vm.dynamicGradiantBackground } },
    [
      _c(
        "m-toolbar",
        {
          staticClass: "transparent",
          attrs: {
            slot: "toolbar",
            dark: "",
            "text-color": "white--text",
            title: "Home"
          },
          slot: "toolbar"
        },
        [
          _c(
            "template",
            { slot: "toolbarButtons" },
            [
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: !_vm.$vuetify.breakpoint.smAndUp,
                        to: { name: "Messages" },
                        color:
                          _vm.G.messagesWatch &&
                          _vm.G.messagesWatch.newMessagesCount > 0
                            ? "warning lighten-3"
                            : "white"
                      }
                    },
                    [
                      _c(
                        "v-badge",
                        { attrs: { color: "error" } },
                        [
                          _vm.G.messagesWatch &&
                          _vm.G.messagesWatch.newMessagesCount > 0
                            ? _c("small", {
                                attrs: { slot: "badge" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.G.messagesWatch.newMessagesCount > 99
                                      ? "99+"
                                      : _vm.G.messagesWatch.newMessagesCount
                                  )
                                },
                                slot: "badge"
                              })
                            : _vm._e(),
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c("span", [_vm._v("Messages")])
                            : _vm._e(),
                          _c("v-icon", {
                            attrs: { right: _vm.$vuetify.breakpoint.smAndUp },
                            domProps: { textContent: _vm._s("message") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "max-width": "400",
                            left: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            flat: "",
                                            icon: !_vm.$vuetify.breakpoint
                                              .smAndUp,
                                            color:
                                              _vm.notificationIconSettings.color
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-badge",
                                          { attrs: { color: "error" } },
                                          [
                                            _vm.G.notificationsData.newCount > 0
                                              ? _c(
                                                  "small",
                                                  {
                                                    attrs: { slot: "badge" },
                                                    slot: "badge"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.G.notificationsData
                                                          .newCount
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.$vuetify.breakpoint.smAndUp
                                              ? _c("span", [
                                                  _vm._v("Notifications")
                                                ])
                                              : _vm._e(),
                                            _c("v-icon", {
                                              attrs: {
                                                right:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndUp
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.notificationIconSettings
                                                    .icon
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3660716128
                          ),
                          model: {
                            value: _vm.notificationsVisible,
                            callback: function($$v) {
                              _vm.notificationsVisible = $$v
                            },
                            expression: "notificationsVisible"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto baby-scroll",
                              staticStyle: {
                                "max-height": "80vh",
                                "overflow-y": "auto"
                              },
                              attrs: { width: "400" }
                            },
                            [
                              _c("notifications-view", {
                                attrs: { visible: _vm.notificationsVisible }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-dialog",
                        {
                          attrs: {
                            fullscreen: "",
                            "hide-overlay": "",
                            transition: "dialog-bottom-transition",
                            scrollablex: ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          flat: "",
                                          icon: !_vm.$vuetify.breakpoint
                                            .smAndUp,
                                          color:
                                            _vm.notificationIconSettings.color
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-badge",
                                        { attrs: { color: "error" } },
                                        [
                                          _vm.G.notificationsData.newCount > 0
                                            ? _c(
                                                "small",
                                                {
                                                  attrs: { slot: "badge" },
                                                  slot: "badge"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.G.notificationsData
                                                        .newCount
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.$vuetify.breakpoint.smAndUp
                                            ? _c("span", [
                                                _vm._v("Notifications")
                                              ])
                                            : _vm._e(),
                                          _c("v-icon", {
                                            attrs: {
                                              right:
                                                _vm.$vuetify.breakpoint.smAndUp
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.notificationIconSettings
                                                  .icon
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.notificationsVisible,
                            callback: function($$v) {
                              _vm.notificationsVisible = $$v
                            },
                            expression: "notificationsVisible"
                          }
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: { dense: "", dark: "", color: "primary" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.notificationsVisible = false
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                              _c("v-toolbar-title", [_vm._v("Notifications")])
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            [
                              _c("notifications-view", {
                                attrs: { visible: _vm.notificationsVisible },
                                on: {
                                  close: function($event) {
                                    _vm.notificationsVisible = false
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "dynamic-split-view",
        { attrs: { "body-color": "transparent" } },
        [_c(_vm.home, { tag: "component" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }