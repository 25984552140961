var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "", "grid-list-lg": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: _vm.isMobile, "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c(
                "v-card-actions",
                [
                  _c("h3", [_vm._v("Messages")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        round: "",
                        flat: "",
                        small: "",
                        to: { name: "Messages" }
                      }
                    },
                    [_vm._v("See All")]
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.isLoadingThreads
                    ? _c(
                        "div",
                        { staticClass: "text-center", attrs: { "pa-2": "" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "" }
                          })
                        ],
                        1
                      )
                    : _vm.threadsError
                    ? _c(
                        "div",
                        { staticClass: "flex text-xs-center xs12 red--text" },
                        [_vm._v("- Error -")]
                      )
                    : _vm.filteredThreads.length === 0
                    ? _c("div", { staticClass: "flex text-xs-center xs12" }, [
                        _vm._v("- Empty -")
                      ])
                    : _c(
                        "v-list",
                        { attrs: { "two-line": "" } },
                        [
                          _vm._l(_vm.filteredThreads, function(thread, index) {
                            return [
                              index > 0
                                ? _c("v-divider", { key: index })
                                : _vm._e(),
                              _c(
                                "v-list-tile",
                                {
                                  key: thread.id,
                                  attrs: {
                                    ripple: "",
                                    to: {
                                      name: "Messages__Message",
                                      params: { id: thread.id }
                                    },
                                    color: "accent darken-4",
                                    "ma-0": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-avatar",
                                    [
                                      _vm.avatarFor(thread.seeker)
                                        ? _c("img", {
                                            attrs: {
                                              src: _vm.avatarFor(thread.seeker)
                                            }
                                          })
                                        : _c("v-icon", [
                                            _vm._v("account_circle")
                                          ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        {
                                          staticClass:
                                            "font-weight-bold d-flex row"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "ellipsis" },
                                            [_vm._v(_vm._s(thread.subject))]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "grey--text text--lighten-1 text-xs-right"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    thread.lastMessageDate,
                                                    "calendar"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-sub-title",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text--primary" },
                                            [_vm._v(_vm._s(thread.fromHeader))]
                                          ),
                                          _vm._v(
                                            "\n                  —\n                  " +
                                              _vm._s(thread.fromSubHeader) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                ],
                1
              )
            ],
            1
          ),
          false
            ? _c(
                "v-flex",
                { attrs: { xs12: "", sm5: "" } },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c("h3", [_vm._v("Applicants")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            round: "",
                            flat: "",
                            small: "",
                            to: { name: "Orgs_Discover" }
                          }
                        },
                        [_vm._v("See All")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "layout",
                      staticStyle: { overflow: "auto" }
                    },
                    _vm._l(_vm.applicantsFilters, function(filter) {
                      return _c(
                        "v-btn",
                        {
                          key: filter.text,
                          class: [
                            {
                              "accent-gradiant":
                                _vm.selectedApplicantFilter === filter
                            },
                            "ml-0 mr-0"
                          ],
                          attrs: { round: "", flat: "", small: "" },
                          on: {
                            click: function($event) {
                              _vm.selectedApplicantFilter = filter
                            }
                          }
                        },
                        [_vm._v(_vm._s(filter.text))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.isLoadingApplicants
                        ? _c(
                            "div",
                            {
                              staticClass: "text-center",
                              attrs: { "pa-2": "" }
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" }
                              })
                            ],
                            1
                          )
                        : _vm.applicantsError
                        ? _c(
                            "div",
                            {
                              staticClass: "flex text-xs-center xs12 red--text"
                            },
                            [_vm._v("- Error -")]
                          )
                        : _vm.filteredApplicants.items.length === 0
                        ? _c(
                            "div",
                            { staticClass: "flex text-xs-center xs12" },
                            [_vm._v("- Empty -")]
                          )
                        : _c(
                            "v-container",
                            {
                              staticClass: "pa-0",
                              attrs: { fluid: "", "grid-list-lg": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  style: _vm.isMobile
                                    ? { "overflow-x": "auto" }
                                    : null,
                                  attrs: {
                                    row: "",
                                    wrap: !_vm.isMobile,
                                    "pb-4": "",
                                    "pt-2": ""
                                  }
                                },
                                [
                                  _vm._l(_vm.filteredApplicants.items, function(
                                    applicant
                                  ) {
                                    return _c(
                                      "v-flex",
                                      {
                                        key: applicant.id,
                                        style: _vm.isMobile
                                          ? { "min-width": "50vw" }
                                          : null,
                                        attrs: { xs12: "", md6: "", lg4: "" }
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Seeker_Profile",
                                                params: {
                                                  id: applicant.seekerProfile.id
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "seeker-card",
                                              {
                                                attrs: {
                                                  "seeker-profile":
                                                    applicant.seekerProfile,
                                                  small: false,
                                                  "no-video": "",
                                                  dark: ""
                                                }
                                              },
                                              [
                                                _c("div", {
                                                  attrs: {
                                                    slot: "card-actions"
                                                  },
                                                  slot: "card-actions"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.filteredApplicants.hasNext
                                    ? _c(
                                        "v-flex",
                                        {
                                          style: _vm.isMobile
                                            ? { "min-width": "50vw" }
                                            : null,
                                          attrs: { xs12: "", md6: "", lg4: "" }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "elevation-11 seeker-card text-center",
                                              attrs: {
                                                to: { name: "Orgs_Discover" },
                                                dark: "",
                                                ripple: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-responsive",
                                                {
                                                  attrs: { "aspect-ratio": "1" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "fill-height",
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                        "justify-content":
                                                          "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("more_horiz")
                                                          ]),
                                                          _c("div", [
                                                            _vm._v("More")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c(
                "v-card-actions",
                [
                  _c("h3", [_vm._v("Job Posts")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        round: "",
                        flat: "",
                        small: "",
                        to: { name: "Orgs_JobPosts" }
                      }
                    },
                    [_vm._v("See All")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "layout", staticStyle: { overflow: "auto" } },
                _vm._l(_vm.jobPostFilters, function(filter) {
                  return _c(
                    "v-btn",
                    {
                      key: filter.text,
                      class: [
                        {
                          "accent-gradiant":
                            _vm.selectedJobPostFilter === filter
                        },
                        "ml-0 mr-0"
                      ],
                      attrs: { round: "", flat: "", small: "" },
                      on: {
                        click: function($event) {
                          _vm.selectedJobPostFilter = filter
                        }
                      }
                    },
                    [_vm._v(_vm._s(filter.text))]
                  )
                }),
                1
              ),
              _c(
                "div",
                [
                  _vm.filteredJobPosts.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-center", attrs: { "pa-2": "" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "" }
                          })
                        ],
                        1
                      )
                    : _vm.filteredJobPosts.error
                    ? _c(
                        "div",
                        { staticClass: "flex text-xs-center xs12 red--text" },
                        [_vm._v("- Error -")]
                      )
                    : _vm.filteredJobPosts.items.length === 0
                    ? _c("div", { staticClass: "flex text-xs-center xs12" }, [
                        _vm._v("- Empty -")
                      ])
                    : _c(
                        "job-cards-list",
                        {
                          staticClass: "pt-2",
                          attrs: {
                            "job-posts": _vm.filteredJobPosts.items,
                            "meta-visible": false
                          },
                          on: { "card-click": _vm.openJobPost }
                        },
                        [
                          _vm.filteredJobPosts.hasNext
                            ? _c(
                                "v-flex",
                                {
                                  attrs: { slot: "footer", xs12: "" },
                                  slot: "footer"
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "text-center elevation-17",
                                      attrs: {
                                        to: { name: "Orgs_JobPosts" },
                                        dark: "",
                                        ripple: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-icon", [_vm._v("more_horiz")]),
                                          _c("div", [_vm._v("More")])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", {
                            attrs: { slot: "card-actions" },
                            slot: "card-actions"
                          })
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }