

















































































































import Vue from "vue";
import OrgsHome from "@/pages/orgs/home.vue";
import SeekersHome from "@/pages/seekers/home.vue";
import notificationsView from "@/components/notifications/notificationsView.vue";
import { G, util } from "@/globals";

export default {
  name: "Home",
  components: { notificationsView },

  created: function() {
    // if (this.isSeekerMode) {
    //   this.$router.push({
    //     name: "Seekers_Discover"
    //   });
    // }
  },
  mounted: function() {},
  data: () => ({
    G: G.data,
    notificationsVisible: false
  }),
  methods: {
    showNotifications: function() {}
  },
  activated: function() {
    // this.$router.push({
    //   name: this.isSeekerMode ? "Seekers_Discover" : "Orgs_Discover"
    // });
  },
  computed: {
    home() {
      return this.isSeekerMode ? SeekersHome : OrgsHome;
    },
    notificationIconSettings: function() {
      if (this.notificationPermissionStatus == "denied") {
        return { color: "error lighten-1", icon: "notification_important" };
      } else if (G.data.notificationsData.newCount > 0) {
        return {
          color: "warning lighten-3",
          icon: "notifications_active"
        };
      } else {
        return {
          color: "white",
          icon: "notifications"
        };
      }
    }
  }
};
